import { NextPage } from "next";
import { useRouter } from "next/router";
import React from "react";

import { Grid, GridColumn } from "@components/Grid";
import { Loader } from "@components/Loader";

export const fallbackViewWrapper =
  (view: React.FC<any> | NextPage<any>) => (props: any) => {
    const { isFallback } = useRouter();
    const Component = view;

    return isFallback ? (
      <Grid onlyInnerSpacing verticalSpacing={5}>
        <GridColumn />
        <GridColumn>
          <div style={{ position: "relative" }}>
            <Loader verticalCenter />
          </div>
        </GridColumn>
        <GridColumn />
      </Grid>
    ) : (
      <Component {...props} />
    );
  };
