import { media, px2rem, styled, theme } from "@styles";

export const MaintenancePageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  width: 100vw;

  ${media.md.up} {
    overflow: hidden;
  }
`;

export const MaintenancePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  height: 100vh;
  margin: 0 auto;
  padding: 10vh 2rem;
  max-width: 1200px;
`;

export const MaintenancePageContent = styled.div`
  align-items: center;
  display: flex;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: left;
`;

export const MaintenancePageHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: ${theme.spacing.gutter};
  margin-bottom: ${px2rem(32)};
`;

export const MaintenancePageHeaderTitle = styled.header`
  font-size: ${px2rem(48)};
  font-weight: 400;
  margin-bottom: ${px2rem(18)};

  ${media.md.up} {
    font-size: ${px2rem(64)};
  }
`;

export const MaintenancePageSubheader = styled.p`
  font-family: ${({ theme }) => theme.typography.baseFontFamily};
  font-weight: 700;
  font-size: ${px2rem(24)};
  line-height: 1.2;
  text-transform: none;
  padding-top: 8px;
  padding-bottom: 8px;

  ${media.md.up} {
    font-weight: 400;
    font-size: ${px2rem(32)};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
