import React from "react";
import { FormattedMessage } from "react-intl";

export const Title = (
  <FormattedMessage
    defaultMessage="The site is currently offline for maintenance"
    id="JTFd0Z"
  />
);

export const SubTitle = (
  <FormattedMessage
    defaultMessage="We're making some scheduled improvements to the site, but will be back online shortly"
    id="e/5RhP"
  />
);
