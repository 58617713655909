import { fallbackViewWrapper } from "@ssr/hoc";

import { NextPageWithLayout } from "@utils";
import { ServerMaintenanceView } from "@views/ServerMaintenanceView/ServerMaintenanceView";

const MaintenancePage: NextPageWithLayout = fallbackViewWrapper(
  ServerMaintenanceView
);

export default MaintenancePage;

MaintenancePage.layoutProps = {
  variant: "dark",
};
