import React from "react";

import { Logo } from "@components/Logo/Logo";
import { Seo } from "@components/Seo/Seo";

import * as M from "./messages";
import * as S from "./styles";

export const ServerMaintenanceView = () => (
  <>
    <Seo nofollow noindex withHreflang />

    <S.MaintenancePageContainer>
      <S.MaintenancePageWrapper>
        <S.LogoWrapper>
          <Logo size="100px" />
        </S.LogoWrapper>
        <S.MaintenancePageContent>
          <S.MaintenancePageHeader>
            <S.MaintenancePageHeaderTitle>
              {M.Title}
            </S.MaintenancePageHeaderTitle>
            <S.MaintenancePageSubheader>
              {M.SubTitle}
            </S.MaintenancePageSubheader>
          </S.MaintenancePageHeader>
        </S.MaintenancePageContent>
      </S.MaintenancePageWrapper>
    </S.MaintenancePageContainer>
  </>
);
